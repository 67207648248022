<template>
    <div class="userdashboard">
           <Navbar/>
         <div class="container-fluid page-body-wrapper">
     
             <SideBar/>

            <div class="main-panel">

                <div class="content-wrapper">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                            <div class="col-md-12 grid-margin">
                                <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">
                                    <h2 class="form-heading ">Latest Tickets</h2>
                                    <a class="mouse-thum" @click="$router.push('/allticket')"><small>Show All</small></a>
                                    </div>
                                    <div class="table-responsive">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th>Ticket No.</th>
                                            <th>Title</th>
                                            <th> Date</th>
                                            <th>Priority</th>
                                            <th>Status</th>
                                            
                                            
                                        </tr>
                                        </thead>
                                        <tbody>
                                         <template v-for="(alldata,index) in AllTickets">
                                            <tr :key="alldata.id" v-if="index<10">
                                                <td>{{alldata.id}}</td>
                                                <td>{{alldata.title}}</td>
                                                <td>{{alldata.udate}}</td>
                                                <td>
                                                    <label class="badge badge-danger" v-if="alldata.priority=='low'" >Low</label>
                                                    <label class="badge badge-warning" v-if="alldata.priority=='medium'" >Medium</label>
                                                    <label class="badge badge-success" v-if="alldata.priority=='high'" >High</label>
                                                </td>
                                                <td>
                                                    <label class="badge badge-danger" v-if="alldata.status==0" >Pending</label>
                                                    <label class="badge badge-warning" v-if="alldata.status==1" >In progress</label>
                                                    <label class="badge badge-success" v-if="alldata.status==2" >Completed</label>
                                                </td>
                                            
                                            </tr>
                                         </template>
                                        
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                </div>
                            </div> 
                            </div>
                        </div>
                        
                     </div>
                </div>

                <Footer/>
            </div>

         </div>

    </div>
</template>

<script>
    import Navbar from '../components/Navbar'
    import SideBar from '../components/SideBar'
    import Footer from '../components/Footer'
    import axios from 'axios'
    export default {
        name: 'Home',
        components: {
            Navbar,
            SideBar,
            Footer,
        },
        data () {
            return {
            AllTickets: [],
            uid: localStorage.getItem('uid'),
            

            }
        },
        mounted () {
            /// /console.log(this.$gmapOptions.load.key)
        // this.ip_address = JSON.parse(localStorage.getItem('ip_address'))
            //this.getData()
            this.getAllTickets();
            window.scrollTo(0, 0)
        },
        methods: {
            
            getAllTickets(){
                var ev=this;
                   axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'getMyTickets',
                            uid: ev.uid,
                        }
                    }).then(function (response) {
                        var data=response.data;
                        console.log(data)
                        console.log(data);
                        if(data.count>0){
                            ev.AllTickets=data.alldata;
                        }

                    })
            },

        }
    }
</script>
<style scoped>
    .mouse-thum{
        cursor: pointer;
    }
     .form-heading{
        margin-bottom: 50px;
        font-weight: bold;
        color:#1c45ef;
    }
</style>