<template>
  <div id="app">

    <router-view/>
  

  </div>
</template>
<script>
import { MyMixin } from './assets/mixin/common'
import axios from 'axios'

//require('./assets/css/font-awesome/all.min.css')
//require('./assets/css/bootstrap/bootstrap.min.css')
//require('./assets/css/style.css')
require('./assets/js/bootstrap/bootstrap.min.js')
//require('./assets/css/mss.css')
//require('./assets/css/main.css')

require('./assets/vendors/iconfonts/mdi/css/materialdesignicons.css');
require('./assets/vendors/iconfonts/ionicons/dist/css/ionicons.css');
require('./assets/vendors/flag-icon-css/css/flag-icon.min.css');
require('./assets/vendors/css/vendor.bundle.addons.css');
require('./assets/vendors/font-awesome/css/font-awesome.min.css');
require('./assets/css/shared/style.css');
require('./assets/css/demo_1/style.css');


//require('./assets/vendors/js/vendor.bundle.base.js');
//require('./assets/vendors/js/vendor.bundle.addons.js');
require('./assets/js/shared/off-canvas.js');
//require('./assets/js/shared/misc.js');


export default {
  mixins: [MyMixin],
  components: {
    
  },
  data () {
    return {
      chknav: this.$route.name,
      ip_address: JSON.parse(localStorage.getItem('ip_address'))

    }
  },
  mounted () {
   // this.getConfig()
    // console.log(this.$route.name)
   // this.myIpData()
  },
  watch: {
    chknav: function (val) {
      this.chknav = val
      // console.log("sasdas")
      // console.log(val)
    }
  },
  methods: {
    async getConfig () {
      const response = await axios.get(this.$root.URL_ROOT + 'api.php?type=configdata')
      this.$store.commit('getConfig', response.data)
      // console.log(this.$store.state.config)
    },
    myIpData () {
      var ip_address = JSON.parse(localStorage.getItem('ip_address'))

      if (ip_address == null) {
        var min = 10000000000
        var max = 99999999999
        var num = Math.floor(Math.random() * (max - min + 1)) + min
        localStorage.setItem('ip_address', JSON.stringify(num))
        // console.log("new ip");
        // console.log(num);
      } else {
        // console.log("old ip(local storage)")
        // console.log(ip_address)

      }
    }

  }
}
</script>
<style>
.hide{
    display: none;
}
.card{
    overflow: auto;
}
img{
    cursor: pointer !important;
}
.foot-img{margin-left:50px;}
.footer-p {
    font-size: 22px;
    color: #058abe;
    float: right;
    width: 20px;
}
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&amp;display=swap);
@import url(https://fonts.googleapis.com/css2?family=Marck+Script&display=swap);
.hide{display:none;}
.btn-primary:hover {
    background: #058abe;
    border-color: #058abe;
    color: #fff;
}

.left-ar{position: absolute;
    left: -20px;
    bottom: 28px;
	width: 30px; z-index:999;}
.right-ar{position: absolute;
    right: 10px;
    bottom: 28px;
	z-index:999;
	width: 30px;}
.btn-outline-primary:hover {
    background: unset;
    color: #024561;
    border-color:#008bea;
}
.btn-outline-primary{
    background: unset;
    color: #0494e0;
    border-color:(to right, #29e086, #069ada);
}
.input-f{width: 72px;
    cursor: text;}
.bg-bubble{position: absolute;
    width: 85%;
    right: 0;
    bottom: 0;
	z-index:-1}
.mss-mt-2{ margin-top:35px;}
.pd-log{padding:0 30px!important;}
.feature-info-title{ margin:0 25px;font-size:26px;}
.header.default .navbar .navbar-nav .nav-link{padding:0px 20px!important;margin:0px!important;}
.smokey-slide {
    background-image: linear-gradient(to bottom right, #29e086, #069ada);
    height: 90px;
    COLOR: #FFF;
    font-size: 30px;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    bottom: 40px;
    right: -130px;
    }
.right-arrow{background:url('./assets/images/right.png');}
.left-arrow{background:url('./assets/images/left.png');}
.smokey{    font-size: 42px;
    font-weight: bold;
    margin-right: 50px;}
.cat{    color: #fff;
    font-size: 33px;
    font-weight: bold;
	line-height: 1.3;
	text-align:center;
	}
.bottom-right {
  position: absolute;
  bottom: 10px;
  right:40px;
}
.offer{COLOR: #fff;
    font-size: 54px;
    font-weight: bold;
    line-height: 1.2;}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mt-pd{padding: 25px 100px 50px 100px;}
.slider-h4{	font-size: 14px;
    color: #000;
    font-weight: bold;
    margin: 10px 5px 2px 5px	}
.slider-p{color:#000;font-size:10px;    margin: 0;}
.slider-a{color:#0aa3ce;margin-bottom:5px;}
.slider-a:hover{color:#000;margin-bottom:5px;}
.slider-div-1{color:#000;font-size:19px;}
.slider-div-2{color:#0aa3ce;font-size:22px;margin-bottom:25px;}
.right-1{opacity:0.4!important;}
.left-1{opacity:0.4!important;}
.btn:hover{color:#fff!important;}
#carousel3d .carousel-3d-slide {
	border-radius: 20px;
padding:0!important;
border:none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;

  -webkit-transition: all .4s;
  transition: all .4s;
}
.pd-15{padding:15px!important;}
#carousel3d .carousel-3d-slide.current {
  background-color: #fff;
  color: #000;
}
#carousel3d .carousel-3d-slide.current span {
  font-size: 20px;
  font-weight: 500;
}
 .text-gray{color:#989494!important;}
 .days{margin:50px;}
 .day-btn{width: 10px;
    position: relative;
    top: -36px;
    left: 120px;}
.day-span {
    color: #0aa3ce;
    left: 75px;
    position: relative;
    font-size: 12px;
    display: block;
}
 .text-black{color: #000!important;
    font-weight: bold;font-size:18px;}
 .contribution-area{display:flex;margin:50px 0;}
 .p-text{width:85px;}
 .food-font{color:#515151}
 .food-font-i{color:#0aa3ce;font-size:24px;margin:30px;}
 .price{color:#0aa3ce;font-size:42px;margin:20px;font-weight:bold;}
 .btn {
  flex: 1 1 auto;
  margin: 10px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  border-radius: 10px;
 }
.btn-shadow{box-shadow:0 0 10px #656565;}
/* Demo Stuff End -> */

/* <- Magic Stuff Start */

.btn:hover {
  background-position: right center; /* change the direction of the change here */
}

.btn-1 {
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
}

.btn-2 {
  background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
}

.btn-3 {
  background-image:linear-gradient(#29e086, #069ada);
  width: 250px;
  }

.btn-4 {
  background-image: linear-gradient(to right, #a1c4fd 0%, #c2e9fb 51%, #a1c4fd 100%);
}
.btn-5 {
  background-image:linear-gradient(#29e086, #069ada);
  margin:0;
  }
  html{ overflow-x:hidden;width:100%}
.line{
    border-right: 3px dashed #c1c1c1;
    position: absolute;
    height: 230px;
    z-index: -1;
    left: 50px;
    top: 1px;
}
  .ul {
  list-style-image: url('./assets/images/line.png');
      position: relative;
    left: -25px;
}
  .footer-img{width:100%;}
  .module-border-wrap {
  max-width: 250px;
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, red, purple);
  padding: 3px;
}
  .btn-primary{background:#058abe;color:#fff;}
  .text-list{color:#fff;font-size:18px;}
  .main-container{margin: 0;
    padding: 0;
    max-width: 100%;}
  .side-dog{width:100%;}
  .space-sec{margin-top:50px;}
  .shop-main-img{width:100%; height:99%;}
  .mt-50{margin:0px 50px;}
  .shop-img{width:100%;}
  .shop-margin{margin:0;padding:0 0px 5px 5px;}
  .theme-bg{ background-image: linear-gradient(to bottom right, #29e086, #069ada);}
  .theme-img{  position: absolute;
    z-index: 999;
    bottom: -71px;
    left: 50%;
    height: 600px;
    transform: translate(-50%);}
.join{width:100%;}
.mission-img{width:85%;}
.bg-2{background:#dadada;}
.about{margin-top:75px;}
.feature-info-content{display:inline-block}
.feature-info-content i{ font-size:22px;}
  .theme-heading{ font-size:340px!important;margin-top:100px!important;margin-left:50px!important;text-shadow: 8px 12px #ffffff21;}
  .btn-dark{ background-color:#4a8086; border-color:#4a8086;}
.adp-h1{font-size:140px;color:#c18c5b;margin: 5px 70px 0 70px;}
.adp-i{font-family: 'Marck Script', cursive;font-size:75px;color:#6b5336;margin: 0 70px 0 70px;}
.adp-p{ font-size:22px;color:#4a8086;margin: 0 70px 0 70px; }
.btn-outline-primary:focus {
    background: unset!important;
    color: #0494e0;
    border-color: #0494e0;
}
.adp-btn {
    display: block;
    margin: 10px 70px 70px 70px;
    border-color: #008bea;
    border-radius: 50px;
    width: 380px;
    color: #024561;
    font-size: 28px;
    box-shadow: 3px 2px #0aa1d238;
    text-transform: capitalize;
    border-left: 1px solid #27dc8b;
    border-top: 1px solid #27dc8b;
}
.foot-mobile{text-align: unset;}
.app-btn{
    display: block;
    margin: 30px 70px 20px 0;
    border-color: #008bea;
    border-radius: 10px;
    width: 280px;
}
.adp-img{position: absolute;
    bottom: 28px;
    right: -74px;
    width: 100%;}
	.dog-icon{width:85px;margin-right:25px;}
	.feature-info-content p{margin-left:137px;}
@media only screen and (max-width: 600px) {
.adp-btn {
    display: block;
    margin: 10px 70px 70px 70px;
    border-color: #008bea;
    border-radius: 20px;
    width: auto;
    color: #024561;
    font-size: 18px;
    padding:10px;
}
.foot-mobile{text-align: center;}
.footer-p {
    font-size: 22px;
    color: #058abe;
    float: unset;
    width: unset;
}
.smokey {
    font-size: 24px;
    font-weight: bold;
    margin-right: 30px;
}
.smokey-slide {
    background-image: linear-gradient(to bottom right, #29e086, #069ada);
    height: 60px;
    COLOR: #FFF;
    font-size: 18px;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: unset;
    }
    .left-ar {
    position: absolute;
    left: 5px;
    bottom: 12px;
    width: 30px;
    z-index: 999;
}
.space-sec {
    margin-top: 0;
}
.right-ar {
    position: absolute;
    right: 8px;
    bottom: 12px;
    z-index: 999;
    width: 30px;
}
.price {
    color: #0aa3ce;
    font-size: 36px;
    margin: 0px;
    font-weight: bold;
}
.mission-img{width:350px;}
.about {
    margin-top: 30px;
}
  .theme-img{  position: absolute;
    /* top: 33px; */
    z-index: 999;
    left: 50%;
    height: 300px;
    bottom: -36px;}
	.theme-heading {
    font-size: 100px!important;
    position: relative;
    top: 100px;
    margin-left: 0px!important;
}
.line {
    border-right: 3px dashed #c1c1c1;
    position: absolute;
    height: 230px;
    z-index: -1;
    left: 40px;
    top: 1px;
}
.feature-info .feature-info-content p {
    margin-bottom: 0;
    position: relative;
    top: -25px;
    font-size: 18px;
}
.mt-pd {
    padding: 5px 25px 50px 25px;
}
.mt-50 {
    margin: 0px 0px;
}
.feature-info-content p {
    margin-left: 110px;
}
.banner-03 {
    position: relative;
    z-index: 0;
    height: 300px;
    overflow: hidden;

}
.adp-img {
    position: absolute;
    bottom: 0px;
    right: 45px;
    width: 74%;
}
.adp-h1 {
    font-size: 83px;
    color: #c18c5b;
    margin: 50px 50px 0 40px;
}
.adp-i {
    font-family: 'Marck Script', cursive;
    font-size: 45px;
    color: #6b5336;
    margin: 0 70px 0 70px;
}
.shop-main-img {
    width: 102%;
    height: auto;
}
html{ overflow-x:hidden;width:100%}
.hidden-xs{display:none;}
.dog-icon{width:60px}
}
 .dog-icon-content{	display:flex;	}
@media only screen and (min-width: 393px) and (max-width: 767px) and (max-width: 200px) {
.adp-btn {
    display: block;
    margin: 10px 70px 70px 70px;
    border-color: #008bea;
    border-radius: 20px;
    width: auto;
    color: #024561;
    font-size: 18px;
    padding:10px;
}
.foot-mobile{text-align: center;}
.footer-p {
    font-size: 22px;
    color: #058abe;
    float: unset;
    width: unset;
}
.smokey {
    font-size: 24px;
    font-weight: bold;
    margin-right: 30px;
}
.smokey-slide {
    background-image: linear-gradient(to bottom right, #29e086, #069ada);
    height: 60px;
    COLOR: #FFF;
    font-size: 18px;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    padding: 20px;
    position: absolute;
    bottom: 0;
    right: unset;
    }
    .left-ar {
    position: absolute;
    left: 5px;
    bottom: 12px;
    width: 30px;
    z-index: 999;
}
.space-sec {
    margin-top: 0;
}
.right-ar {
    position: absolute;
    right: 8px;
    bottom: 12px;
    z-index: 999;
    width: 30px;
}
.price {
    color: #0aa3ce;
    font-size: 36px;
    margin: 0px;
    font-weight: bold;
}
.mission-img{width:350px;}
.about {
    margin-top: 30px;
}
  .theme-img{  position: absolute;
    /* top: 33px; */
    z-index: 999;
    left: 50%;
    height: 300px;
    bottom: -36px;}
	.theme-heading {
    font-size: 100px!important;
    position: relative;
    top: 100px;
    margin-left: 0px!important;
}
.line {
    border-right: 3px dashed #c1c1c1;
    position: absolute;
    height: 230px;
    z-index: -1;
    left: 40px;
    top: 1px;
}
.feature-info .feature-info-content p {
    margin-bottom: 0;
    position: relative;
    top: -25px;
    font-size: 18px;
}
.mt-pd {
    padding: 5px 25px 50px 25px;
}
.mt-50 {
    margin: 0px 0px;
}
.feature-info-content p {
    margin-left: 110px;
}
.banner-03 {
    position: relative;
    z-index: 0;
    height: 300px;
    overflow: hidden;

}
.adp-img {
    position: absolute;
    bottom: 0px;
    right: 45px;
    width: 74%;
}
.adp-h1 {
    font-size: 83px;
    color: #c18c5b;
    margin: 50px 50px 0 40px;
}
.adp-i {
    font-family: 'Marck Script', cursive;
    font-size: 45px;
    color: #6b5336;
    margin: 0 70px 0 70px;
}
.shop-main-img {
    width: 350px;
    height: auto;
}
html{ overflow-x:hidden;width:100%}
.hidden-xs{display:none;}
.dog-icon{width:60px}
}
</style>
