<template>
    <div class="newticket">
           <Navbar/>
         <div class="container-fluid page-body-wrapper">
     
             <SideBar/>

            <div class="main-panel">

                <div class="content-wrapper">
                    <div class="row">
                        <div class="col-md-2 "></div>
                        <div class="col-md-8 grid-margin stretch-card">
                            <div class="card">
                            <div class="card-body">
                                <h2 class="form-heading">Create New Ticket</h2>
                                
                                
                                <div class="form-group">
                                    <label for="exampleInputName1">Ticket Title</label>
                                    <input type="text" class="form-control" v-model="title" id="exampleInputName1" placeholder="Title">
                                </div>

                                <div class="form-group">
                                    <label for="exampleInputName1">Email   &nbsp; (type in the same email id which you have enrolled for)</label>
                                   
                                    <input type="text" class="form-control" v-model="email" id="exampleInputName1" placeholder="Email">
                                </div>

                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Issue Type</label>
                                        <select class="form-control form-control-lg" v-model="ticket_type" id="exampleFormControlSelect1">
                                            <option value="Course Access Issue">Course Access Issue</option>
                                            <option value="Billing Issue">Billing Issue</option>
                                            <option value="Website Issue" >Website Issue</option>
                                            
                                        </select>
                                </div>

                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Course Enrolled for</label>

                                     <input type="text" class="form-control" v-model="course_enrolled" id="exampleInputName1" placeholder="Course Enrolled for">

                                        
                                </div>

                                <div class="form-group">
                                    <label for="exampleTextarea1">Description</label>
                                    <textarea class="form-control" v-model="description" id="exampleTextarea1" rows="2"></textarea>
                                </div>
                                
                               

                                <div class="form-group ">
                                    <label>Attachment</label>
                                    <input type="file" name="img[]" id="file" ref="file" v-on:change="onChangeFileUpload()" class="file-upload-default">
                                    <div class="input-group col-xs-12">
                                    <input type="text" class="form-control file-upload-info" disabled="" :value="select_file" placeholder="Upload Image , PDF">
                                    <span class="input-group-append">
                                        <button class="file-upload-browse btn btn-info upload-btn" @click="browse_btn" type="button">Browse</button>
                                    </span>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Priority </label>
                                        <select class="form-control form-control-lg" v-model="priority" id="exampleFormControlSelect1">
                                            <option value="low">Low</option>
                                            <option value="medium">Medium</option>
                                            <option value="high">High</option>
                                            
                                        </select>
                                </div>

                                

                                <button type="submit" class="btn btn-success mr-2" @click="save_ticket">Submit</button>
                                <button class="btn btn-light hide">Cancel</button>
                                
                            </div>
                            </div>
                        </div>
                     </div>
                </div>

                <Footer/>
            </div>

         </div>

    </div>
</template>
<style scoped>
    .form-heading{
        margin-bottom: 50px;
        font-weight: bold;
        color:#1c45ef;
    }
    .upload-btn{
        margin: unset;
        padding:8px;
    }
</style>

<script>
    import Navbar from '../components/Navbar'
    import SideBar from '../components/SideBar'
    import Footer from '../components/Footer'
    import axios from 'axios'
    export default {
        name: 'NewTicket',
        components: {
            Navbar,
            SideBar,
            Footer,
        },
        data () {
            return {
                uid: localStorage.getItem('uid'),
                title:null,
                ticket_type:null,
                description:null,
                file:null,
                select_file:null,
                priority:null,
                email:null,
                course_enrolled:null,
            }
        },
        mounted () {
            window.scrollTo(0, 0)
        },
        methods: {
            browse_btn(){
                this.$refs.file.click();
            },
            onChangeFileUpload(){
                this.file = this.$refs.file.files[0];
                this.select_file=this.file.name
            },
            save_ticket(){
                var ev=this;
                
                if(this.title=="" || this.title==null){
                    this.$toasted.global.error({ message : 'Please Enter Ticket Title' });
                }else if(this.email=="" || this.email==null){
                    this.$toasted.global.error({ message : 'Please Enter Email' });
                }else if(this.course_enrolled=="" || this.course_enrolled==null){
                    this.$toasted.global.error({ message : 'Please Select Course Enrolled' });
                }else if(this.ticket_type=="" || this.ticket_type==null){
                    this.$toasted.global.error({ message : 'Please Select Issue Type' });
                }else if(this.description=="" || this.description==null){
                    this.$toasted.global.error({ message : 'Please Enter Ticket Description' });
                }else if(this.priority=="" || this.priority==null){
                    this.$toasted.global.error({ message : 'Please Select Ticket Priority' });
                }else{
                        let formData = new FormData();
                        formData.append('file', this.file);
                        formData.append('title', this.title);
                        formData.append('uid', this.uid);
                        formData.append('ticket_type', this.ticket_type);
                        formData.append('description', this.description);
                        formData.append('priority', this.priority);
                        formData.append('type', 'save_ticket');
                        formData.append('email', this.email);
                        formData.append('course_enrolled', this.course_enrolled);
                        
                        axios.post(this.$root.URL_ROOT +'new_file.php',
                            formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            ).then(function(response){
                               var data=response.data;
                               console.log(data)
                               if(data.status=="success"){
                                   ev.$toasted.global.success({ message : data.msg });
                                   ev.$router.push('/allticket');

                               }else{
                                   ev.$toasted.global.error({ message : data.msg });
                               } 
                            })


                        }

            },
            
            

        }
    }
</script>