<template>
    <div>

        <div class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
            <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
            
            

            <div class="row w-100">
                 <div class=" logo-div col-lg-12 ">
                        <img src="../assets/images/impact-logo.png" class="logo-imgs" >
                    </div>
                <div class="col-lg-4 mx-auto">
                   
                <div class="auto-form-wrapper">
                    
                    <div class="form-group">
                        <label class="label">Username</label>
                        <div class="input-group">
                        <input type="text" v-model="username" class="form-control" placeholder="Username">
                        <div class="input-group-append">
                            <span class="input-group-text">
                            <i class="mdi mdi-check-circle-outline"></i>
                            </span>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="label">Password</label>
                        <div class="input-group">
                        <input type="password" v-model="password" class="form-control" placeholder="*********">
                        <div class="input-group-append">
                            <span class="input-group-text">
                            <i class="mdi mdi-check-circle-outline"></i>
                            </span>
                        </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary submit-btn btn-block" @click="login">Login</button>
                    </div>
                    <div class="form-group d-flex justify-content-between">
                        <div class="form-check form-check-flat mt-0">
                        <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" checked> Keep me signed in </label>
                        </div>
                        <a href="#" class="text-small forgot-password text-black hide">Forgot Password</a>
                    </div>
                   
                   
                    
                </div>
               
                 </div>
            </div>
            </div>
            <!-- content-wrapper ends -->
        </div>
        <!-- page-body-wrapper ends -->
        </div>

    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        name: 'AdminLogin',
        components: {
        
        },
        data () {
            return {
                username:null,
                password:null,
                userdata:[],
            }
        },
        mounted () {

            console.log(localStorage.getItem('uid'));
            if(this.uid==0){
              this.$router.push('/admindashboard');

            }

        },
        methods: {
            login(){
             if(this.username==null || this.username==""){
                  this.$toasted.global.error({ message: 'Please Enter Username' })
             }else if(this.password==null || this.password==""){
                 this.$toasted.global.error({ message: 'Please Enter Password'})
             }else{
                  var ev = this
                    axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'adminlogin',
                            username: ev.username,
                            password: ev.password,
                            uid:localStorage.getItem('uid'),
                            }

                    }).then(function (response) {
                        var data = response.data;
                        if(data.status=='success'){
                            ev.userdata=data.localdata;
                          
                            ev.$toasted.global.success({ message: data.msg});
                            localStorage.setItem('username', ev.userdata.username);
                            localStorage.setItem('uid', 0);
                            localStorage.setItem('mobile', ev.userdata.mobile);
                            localStorage.setItem('usertype', ev.userdata.usertype);
                            ev.$router.push('/admindashboard');

                        }else{
                            ev.$toasted.global.error({ message: data.msg})
                        }
                       //console.log(response);

                    },"json")
             }
            }
        }
    }

</script>

<style scoped>
.btn{
    margin:unset !important;
}
@media only screen and (max-width: 600px) {
    .logo-imgs{
        width: 100% !important;
    }
}
.logo-imgs{
    width: 40%;
}
.logo-div{
    margin-bottom: 20px;
    padding: 2%;
    background-color: #fff;
    margin-top:-15%;
    text-align: center;
    
}
.auth.auth-bg-1{
     background-color: #fff !important;
   background:unset !important;
}
.auto-form-wrapper{
    /*box-shadow: 0 0px 27.7px 5.3px #69558f  !important;*/
     box-shadow:unset !important;
    border: 1px solid #69558f;
    border-radius:10px;

}
</style>